import React, { lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
// import BookingForm from "./Pages/BookingForm";
// import BookingRequest from "./Pages/BookingRequest";
// import BookingHistory from "./Pages/BookingHistory";
import PrivateRoute from "./AppPrivateRoutes";
// import Profile from "./Pages/Profile";
import { isauthenticated } from "./Library/Utils/auth";
import { Spin } from "antd";
// import Loader from "./Components/core/Loader";

const LoginPage = lazy(() => import("./Pages/Login/index"));
const Dashboard = lazy(() => import("./Pages/Dashboard/index"));
const Profile = lazy(() => import("./Pages/Profile/index"));
const BookingHistory = lazy(() => import("./Pages/BookingHistory/index"));
const BookingRequest = lazy(() => import("./Pages/BookingRequest/index"));
const BookingForm = lazy(() => import("./Pages/BookingForm/index"));
const ForgotPassword = lazy(() => import("./Pages/ForgortPassword/index"));
const ResetPassword = lazy(() => import("./Pages/ResetPassword/index"));
const PageNotFound = lazy(() => import("./Pages/PageNotFound/index"));

export default function AppRoutes() {
    return (
        <BrowserRouter>
            <Suspense fallback={<Spin className="custom-loader" size="large"/>}>
                <Routes>
                    <Route
                        path={"/"}
                        element={<BookingForm />}
                    />
                    <Route
                        path={"/forgot-password"}
                        element={<ForgotPassword />}
                    />
                    <Route
                        path={"/reset-password"}
                        element={<ResetPassword />}
                    />
                    <Route
                        path="/login"
                        element={
                            isauthenticated() === true ? (
                                <Navigate to="/dashboard" />
                            ) : (
                                <LoginPage />
                            )
                        }
                    />
                    <Route path="/" element={<PrivateRoute />}>
                        <Route
                            path="/dashboard"
                            element={<Dashboard />}
                        />
                        <Route
                            path={"/booking-request"}
                            element={<BookingRequest />}
                        />
                        <Route
                            path={"/booking-history"}
                            element={<BookingHistory />}
                        />
                        <Route
                            path={"/profile"}
                            element={<Profile />}
                        />
                    </Route>
                    <Route
                        path="*"
                        element={<PageNotFound />}
                    />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}