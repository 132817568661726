// Third party import
/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUserDataFromLocalStorage } from "../../Library/Utils";
import { AuthUser } from "../../Types/Userdata";


const initialState: AuthUser = getUserDataFromLocalStorage();

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthDetails: (
        state: AuthUser,
        action: PayloadAction<AuthUser>
      ) => {
        state.message = action.payload.message;
        state.access_token = action.payload.access_token;
        state.user.admin.id = action.payload.user.admin.id;
        state.user.admin.first_name = action.payload.user.admin.first_name;
        state.user.admin.last_name = action.payload.user.admin.last_name;
        state.user.admin.profile_picture = action.payload.user.admin.profile_picture;
        state.user.admin.email = action.payload.user.admin.email;
        state.user.admin.created_at = action.payload.user.admin.created_at;
        state.user.admin.updated_at = action.payload.user.admin.updated_at;
      }
  },
});

// Action creators are generated for each case reducer function
export const {
    setAuthDetails
} = authSlice.actions;

export default authSlice.reducer;
