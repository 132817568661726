import LeftSideBar from "./Components/Core/LeftSideBarComponent";
import { isauthenticated } from "./Library/Utils/auth";
import { Navigate, Outlet, useLocation } from "react-router-dom";


export default function PrivteRoutes() {
  
    const location = useLocation();
    
    return isauthenticated() === true ? (
      <>
        {
          location.pathname !== "/" && 
          <>
            <LeftSideBar />
          </>

        } 
        <Outlet />
      </>

    ) : (
      <Navigate to="/login" />
    );
}