import React from 'react';
import './App.css';
import AppRoutes from './AppRoutes';
import { Provider } from 'react-redux';
import store from './Store/store';
import ToastComponents from './Components/UI/toaster';

// CSS
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div>
      <Provider store={store}>
        <AppRoutes />
        <ToastComponents />
      </Provider>
    </div>
  );
}

export default App;
