// Third party
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

// Reducer
import authReducer from "./Reducer/user";
import calenderViewReducer from "./Reducer/calenderView";

const reducer = combineReducers({
  authReducer,
  calenderViewReducer
});

const store = configureStore({
  reducer,
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
