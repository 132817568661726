import { MenuProps, UploadFile } from "antd";
import { AuthUser } from "../../Types/Userdata";


export const pageSize: number = 5;

export const years: {
  value: string,
  label: string
}[] = [
  {
    label: 'Year 5',
    value: 'Year 5',
  },
  {
    label: 'Year 6',
    value: 'Year 6',
  },
  {
    label: 'Year 7',
    value: 'Year 7',
  },
  {
    label: 'Year 8',
    value: 'Year 8',
  }, 
  {
    label: 'Year 9',
    value: 'Year 9',
  }, 
  {
    label: 'Year 10',
    value: 'Year 10',
  },
  {
    label: 'Year 11',
    value: 'Year 11',
  },
  {
    label: 'Year 12',
    value: 'Year 12',
  },
  {
    label: 'Year 13',
    value: 'Year 13',
  },
];

export function validateEmail() {
  const emailRegx = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
  return emailRegx;
}

export function setToLocalStorage(key: string, value: any) {
    localStorage.setItem(key, value);
  }

export function getToLocalStorage(itemName: string) {
  if (itemName === "access_token") {
    return localStorage.getItem(itemName);
  }
  return JSON.parse(localStorage.getItem(itemName) || "null");
}

export function clearLocalStorage() {
  localStorage.clear();
}


export const appointmentFilters: MenuProps['items'] = [
  {
    label: 'Booking Date',
    key: 'booking_date',
  },
  {
    label: 'School Name',
    key: 'school_name',
  },
  {
    label: 'Booking Type',
    key: 'booking_type',
  },
];

export const getUserDataFromLocalStorage = (): AuthUser => {
  const storedData = localStorage.getItem('user_data');
  if (storedData) {
    try {
      const persedData = JSON.parse(storedData);
      return {
        message: persedData?.message,
        access_token: persedData?.access_token,
        user: {
          admin: {
            id: persedData?.user?.admin?.id,
            first_name: persedData?.user?.admin?.first_name,
            last_name: persedData?.user?.admin?.last_name,
            email: persedData?.user?.admin?.email,
            profile_picture: persedData?.user?.admin?.profile_picture,
            updated_at: persedData?.user?.admin?.updated_at,
            created_at: persedData?.user?.admin?.created_at,
          }
        }
      }
    } catch (e) {
      console.error("Failed to perse local storage data: ", e);
    }
  }
  return {
    message: "",
    access_token: "",
    user: {
      admin: {
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        profile_picture: "",
        updated_at: "",
        created_at: "",
      }
    }
  }
}


 // Format the time as "HH:MM:SS.sssZ"
const formatTime = (date: Date) => {
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const formatDateTime = (booking_date: string, booking_start_time: string, booking_end_time: string) => {
  // Parse ISO strings into Date objects
  const bookingDate = new Date(booking_date);
  const bookingStartTime = new Date(booking_start_time);
  const bookingEndTime = new Date(booking_end_time);

  // Format the date as "YYYY-MM-DD"
  const year = bookingDate.getFullYear();
  const month = String(bookingDate.getMonth() + 1).padStart(2, '0'); // Add 1 because months are 0-indexed
  const day = String(bookingDate.getDate()).padStart(2, '0');

  return {
    booking_date: `${year}-${month}-${day}`,
    booking_start_time: formatTime(bookingStartTime),
    booking_end_time: formatTime(bookingEndTime),
  };
};


/**
 * Builds a query string with the provided query parameters.
 *
 * @param {Record<string, any>} params - The query parameters.
 * @returns {string} - The built query string.
 */
export const buildQueryStringWithQueryParams = <T extends Record<string, any>>(
  params: T
): string => {
  const encodedParams = Object.entries(params)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
    )
    .join("&");

  return encodedParams;
};

export const validateFileType = (
  { name }: UploadFile,
  extentions: string[]
) => {
  if (!extentions) {
    return true;
  }

  const isAllowedExtension = extentions.some((ext) =>
    name.toLowerCase().endsWith(ext)
  );
  return isAllowedExtension;
};