import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ICalenderView {
    isCalenderView: boolean
}

const initialState: ICalenderView = {
    isCalenderView: false
}

export const calenderView = createSlice({
    name: "calenderView",
    initialState,
    reducers: {
        setCalenderView: (state: ICalenderView, action: PayloadAction<boolean>) => {
            state.isCalenderView = action.payload;
        }
    }
})

export const {
    setCalenderView
} = calenderView.actions;

export default calenderView.reducer;