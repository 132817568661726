import React, { useEffect, useState } from 'react'
// import Link from 'antd/es/typography/Link'

// images 
import Logo from "../../../Assets/Images/sidebar-logo.svg"
import managementIcon from "../../../Assets/Images/management.svg"
import usericon from "../../../Assets/Images/user.svg"
import calendarIcon from "../../../Assets/Images/Calendar.svg"
import { Link, useLocation, useNavigate } from 'react-router-dom'

const LeftSideBar = () => {
    const location = useLocation();

    const [page, setPage] = useState<string>(location.pathname);

    useEffect(() => {
        setPage(location.pathname);
    }, [location.pathname])

    return (
        <div
            className="left-sidebar-wrapper"
        >
            <Link
                to={"/dashboard"}
                key={""}
                className=""
            >
                <img className="logo" src={Logo} alt="icon" />

            </Link>
            <div className="sidebar-icon-menu">
                <ul>
                    <li>
                        <Link
                            to={"/dashboard"}
                            key={""}
                            className={`menu-item ${page === "/dashboard" ? "active" : ""}`}
                        >
                            <img className="menu-icon" src={managementIcon} alt="icon" />
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={"/booking-request"}
                            key={""}
                            className={`menu-item ${page === "/booking-request" ? "active" : ""}`}
                        >
                            <img className="menu-icon" src={usericon} alt="icon" />
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={"/booking-history"}
                            key={""}
                            className={`menu-item ${page === "/booking-history" ? "active" : ""}`}
                        >
                            <img className="menu-icon" src={calendarIcon} alt="icon" />
                        </Link>
                    </li>
                </ul>
            </div>

        </div>
    )
}

export default LeftSideBar
